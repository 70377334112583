<template>
	<div class="ysm-settings">

		<div class="order">
			<div class="orderno" style="width: 80%;"><span style="font-weight: bolder;">订单编号:{{orderNum}}</span></div>
			<div class="con" style="width: 80%;display: flex;justify-content: space-between;">
				<div class="conditions" align="left">
					<h3>患者信息</h3>
					<div class="hzInfo">
						<p><span class="cu">患者编号:</span> {{patientNum}}</p>
						<p><span class="cu">出生年份:</span> {{patientBirthday}}</p>
						<p><span class="cu">患者性别:</span> {{patientGender}}</p>
					</div>
					<h3>医师信息</h3>
					<div class="hzInfo">
						<p><span class="cu">医师姓名:</span> {{dname}}</p>
						<p><span class="cu">联系方式:</span> {{phone}}</p>
						<p><span class="cu">公司名称:</span> {{company}}</p>
						<p><span class="cu">地址信息:</span> {{stateName}}</p>
					</div>
					<h3>地址信息</h3>
					<div class="hzInfo">
						<p><span class="cu">收货人:</span> {{shouhuoUserName}}</p>
						<p><span class="cu">联系方式:</span> {{shouhuoPhone}}</p>
						<p><span class="cu">收货城市:</span> {{shouhuoCityInfo}}</p>
						<p><span class="cu">收货地址:</span> {{shouhuoAddress}}</p>
					</div>
				</div>

				<div class="right">
                     <div class="top">
						 <div class="hzInfo">
						 	<p><span class="cu">治疗方案:</span> <p style="text-indent: 1em;">{{yalie}}</p></p>
						 	<p><span class="cu">治疗目标:</span> <p v-if="mubiao!=null" style="text-indent: 1em;">{{mubiao}}</p></p>
							<p style="text-indent: 1em;" v-for="item in targetList">
						 		{{item}}
						 	</p>
							<el-row>
						 		<el-col :span="24">
						 			<div id="new-medical-report-ctrl">
						 				<div class="medical-report-ctrl" align="left">
						 					<span style="display: inline-block;margin-bottom: 0.625rem;font-weight: bold;">完整的医学检查</span>
						 					<br />
						 					<table class="container-table">
						 						<tbody>
						 							<tr>
						 								<td class="label-column">右</td>
						 								<td class="label-column-space">
						 									<div></div>
						 								</td>
						 								<td>
						 									<table class="report-table">
						 										<tbody>
						 											<tr class="select-row">
						 												<td>
						 													<div @click="getFind('a18',18)" id="a18"></div>
						 												</td>
						 												<td>
						 													<div @click="getFind('a17',17)" id="a17"></div>
						 												</td>
						 												<td>
						 													<div @click="getFind('a16',16)" id="a16"></div>
						 												</td>
						 												<td>
						 													<div @click="getFind('a15',15)" id="a15"></div>
						 												</td>
						 												<td>
						 													<div @click="getFind('a14',14)" id="a14"></div>
						 												</td>
						 												<td>
						 													<div @click="getFind('a13',13)" id="a13"></div>
						 												</td>
						 												<td>
						 													<div @click="getFind('a12',12)" id="a12"></div>
						 												</td>
						 												<td>
						 													<div @click="getFind('a11',11)" id="a11"></div>
						 												</td>
						 												<td>
						 													<div @click="getFind('a21',21)" id="a21"></div>
						 												</td>
						 												<td>
						 													<div @click="getFind('a22',22)" id="a22"></div>
						 												</td>
						 												<td>
						 													<div @click="getFind('a23',23)" id="a23"></div>
						 												</td>
						 												<td>
						 													<div @click="getFind('a24',24)" id="a24"></div>
						 												</td>
						 												<td>
						 													<div @click="getFind('a25',25)" id="a25"></div>
						 												</td>
						 												<td>
						 													<div @click="getFind('a26',26)" id="a26"></div>
						 												</td>
						 												<td>
						 													<div @click="getFind('a27',27)" id="a27"></div>
						 												</td>
						 												<td>
						 													<div @click="getFind('a28',28)" id="a28"></div>
						 												</td>
						 											</tr>
						 											<tr class="label-row">
						 												<td>8</td>
						 												<td>7</td>
						 												<td>6</td>
						 												<td>5</td>
						 												<td>4</td>
						 												<td>3</td>
						 												<td>2</td>
						 												<td>1</td>
						 												<td>1</td>
						 												<td>2</td>
						 												<td>3</td>
						 												<td>4</td>
						 												<td>5</td>
						 												<td>6</td>
						 												<td>7</td>
						 												<td>8</td>
						 											</tr>
						 											<tr class="label-row">
						 												<td>8</td>
						 												<td>7</td>
						 												<td>6</td>
						 												<td>5</td>
						 												<td>4</td>
						 												<td>3</td>
						 												<td>2</td>
						 												<td>1</td>
						 												<td>1</td>
						 												<td>2</td>
						 												<td>3</td>
						 												<td>4</td>
						 												<td>5</td>
						 												<td>6</td>
						 												<td>7</td>
						 												<td>8</td>
						 											</tr>
						 											<tr class="select-row">
						 												<td>
						 													<div @click="getFind('a48',48)" id="a48"></div>
						 												</td>
						 												<td>
						 													<div @click="getFind('a47',47)" id="a47"></div>
						 												</td>
						 												<td>
						 													<div @click="getFind('a46',46)" id="a46"></div>
						 												</td>
						 												<td>
						 													<div @click="getFind('a45',45)" id="a45"></div>
						 												</td>
						 												<td>
						 													<div @click="getFind('a44',44)" id="a44"></div>
						 												</td>
						 												<td>
						 													<div @click="getFind('a43',43)" id="a43"></div>
						 												</td>
						 												<td>
						 													<div @click="getFind('a42',42)" id="a42"></div>
						 												</td>
						 												<td>
						 													<div @click="getFind('a41',41)" id="a41"></div>
						 												</td>
						 												<td>
						 													<div @click="getFind('a31',31)" id="a31"></div>
						 												</td>
						 												<td>
						 													<div @click="getFind('a32',32)" id="a32"></div>
						 												</td>
						 												<td>
						 													<div @click="getFind('a33',33)" id="a33"></div>
						 												</td>
						 												<td>
						 													<div @click="getFind('a34',34)" id="a34"></div>
						 												</td>
						 												<td>
						 													<div @click="getFind('a35',35)" id="a35"></div>
						 												</td>
						 												<td>
						 													<div @click="getFind('a36',36)" id="a36"></div>
						 												</td>
						 												<td>
						 													<div @click="getFind('a37',37)" id="a37"></div>
						 												</td>
						 												<td>
						 													<div @click="getFind('a38',38)" id="a38"></div>
						 												</td>
						 											</tr>
						 										</tbody>
						 									</table>
						 								</td>
						 								<td class="label-column-space">
						 									<div></div>
						 								</td>
						 								<td class="label-column">左</td>
						 							</tr>
						 						</tbody>
						 					</table>
						 					<span class="jianchaitem">C =全冠义齿, PC =部分冠义齿, P = 桥体, I = 种植体, CC = 连冠义齿,Y = 嵌体,
						 						RT =
						 						根管治疗过</span>
						 					<br />
						 					<span class="jianchaitem">i.b. = 未完全萌出牙, AG = 先天缺失牙, V = 贴面,Ex = 已拔除牙</span>
						 					<br />
						 	
						 				</div>
						 			</div>
						 		</el-col>
						 	</el-row>
						    <p>
								<h3>适用项</h3>
								<el-checkbox-group v-model="checkList" style="margin-bottom: 0.3125rem;padding-left:15px;">
									<el-checkbox  disabled  :label="1" >牙周炎</el-checkbox>
									<el-checkbox disabled :label="2">颞下颌关节紊乱TMJ</el-checkbox>
									<el-checkbox disabled :label="3">其它(请注明)</el-checkbox>
								</el-checkbox-group>
							</p>
							<p style="padding-left: 15px;">{{shuoming}}</p>
							<el-row style="margin-top: 0.625rem;">
								<el-col :span="13">
									<div id="new-select-teeth-ctrl">
										<div class="teeth-selection-ctrl" style="text-align: left;">
											<h3 style="font-weight: bold;">需要移动</h3>
											
											<table class="container-table">
												<tbody>
													<tr>
														<th align="left">患者右侧</th>
														<th align="right">患者左测</th>
													</tr>
													<tr>
														<td colspan="2">
															<table class="selection-table">
																<tbody>
																	<tr class="select-row sh select-row1">
																		<td>
																			<div class="ya17" id="ya17"
																				@click="showDuiHao1('ya17',17)"></div>
																		</td>
																		<td>
																			<div id="ya16" class="ya16"
																				@click="showDuiHao1('ya16',16)"></div>
																		</td>
																		<td>
																			<div id="ya15" class="ya15"
																				@click="showDuiHao1('ya15',15)"></div>
																		</td>
																		<td>
																			<div id="ya14" class="ya14"
																				@click="showDuiHao1('ya14',14)"></div>
																		</td>
																		<td>
																			<div id="ya13" class="ya13"
																				@click="showDuiHao1('ya13',13)"></div>
																		</td>
																		<td>
																			<div id="ya12" class="ya12"
																				@click="showDuiHao1('ya12',12)"></div>
																		</td>
																		<td>
																			<div id="ya11" class="ya11"
																				@click="showDuiHao1('ya11',11)"></div>
																		</td>
																		<td>
																			<div id="ya21" class="ya21"
																				@click="showDuiHao2('ya21',21)"></div>
																		</td>
																		<td>
																			<div id="ya22" class="ya22"
																				@click="showDuiHao2('ya22',22)"></div>
																		</td>
																		<td>
																			<div id="ya23" class="ya23"
																				@click="showDuiHao2('ya23',23)"></div>
																		</td>
																		<td>
																			<div id="ya24" class="ya24"
																				@click="showDuiHao2('ya24',24)"></div>
																		</td>
																		<td>
																			<div id="ya25" class="ya25"
																				@click="showDuiHao2('ya25',25)"></div>
																		</td>
																		<td>
																			<div id="ya26" class="ya26"
																				@click="showDuiHao2('ya26',26)"></div>
																		</td>
																		<td>
																			<div id="ya27" class="ya27"
																				@click="showDuiHao2('ya27',27)"></div>
																		</td>
																	</tr>
																	<tr class="teeth-row sh">
																		<td>
																			<div class="ya17" id="ya17"
																				@click="showDuiHao1('ya17',17)"><img
																					src="../../assets/ysmimg/Zaehne_1.png" />
																			</div>
																		</td>
																		<td>
																			<div class="ya16" id="ya16"
																				@click="showDuiHao1('ya16',16)"><img
																					src="../../assets/ysmimg/Zaehne_2.png" />
																			</div>
																		</td>
																		<td>
																			<div class="ya15" id="ya15"
																				@click="showDuiHao1('ya15',15)"><img
																					src="../../assets/ysmimg/Zaehne_3.png" />
																			</div>
																		</td>
																		<td>
																			<div class="ya14" id="ya14"
																				@click="showDuiHao1('ya14',14)"><img
																					src="../../assets/ysmimg/Zaehne_4.png" />
																			</div>
																		</td>
																		<td>
																			<div class="ya13" id="ya13"
																				@click="showDuiHao1('ya13',13)"><img
																					src="../../assets/ysmimg/Zaehne_5.png" />
																			</div>
																		</td>
																		<td>
																			<div class="ya12" id="ya12"
																				@click="showDuiHao1('ya12',12)"><img
																					src="../../assets/ysmimg/Zaehne_6.png" />
																			</div>
																		</td>
																		<td>
																			<div class="ya11" id="ya11"
																				@click="showDuiHao1('ya11',11)"><img
																					src="../../assets/ysmimg/Zaehne_7.png" />
																			</div>
																		</td>
																		<td>
																			<div class="ya21" id="ya21"
																				@click="showDuiHao2('ya21',21)"><img
																					src="../../assets/ysmimg/Zaehne_8.png" />
																			</div>
																		</td>
																		<td>
																			<div class="ya22" id="ya22"
																				@click="showDuiHao2('ya22',22)"><img
																					src="../../assets/ysmimg/Zaehne_9.png" />
																			</div>
																		</td>
																		<td>
																			<div class="ya23" id="ya23"
																				@click="showDuiHao2('ya23',23)"><img
																					src="../../assets/ysmimg/Zaehne_10.png" />
																			</div>
																		</td>
																		<td>
																			<div class="ya24" id="ya24"
																				@click="showDuiHao2('ya24',24)"><img
																					src="../../assets/ysmimg/Zaehne_11.png" />
																			</div>
																		</td>
																		<td>
																			<div class="ya25" id="ya25"
																				@click="showDuiHao2('ya25',25)"><img
																					src="../../assets/ysmimg/Zaehne_12.png" />
																			</div>
																		</td>
																		<td>
																			<div class="ya26" id="ya26"
																				@click="showDuiHao2('ya26',26)"><img
																					src="../../assets/ysmimg/Zaehne_13.png" />
																			</div>
																		</td>
																		<td>
																			<div class="ya27" id="ya27"
																				@click="showDuiHao2('ya27',27)"><img
																					src="../../assets/ysmimg/Zaehne_14.png" />
																			</div>
																		</td>
																	</tr>
																	<tr class="teeth-row xh">
																		<td>
																			<div class="ya47"
																				@click="showDuiHao4('ya47',47)"><img
																					src="../../assets/ysmimg/Zaehne_15.png" />
																			</div>
																		</td>
																		<td>
																			<div class="ya46"
																				@click="showDuiHao4('ya46',46)"><img
																					src="../../assets/ysmimg/Zaehne_16.png" />
																			</div>
																		</td>
																		<td>
																			<div class="ya45"
																				@click="showDuiHao4('ya45',45)"><img
																					src="../../assets/ysmimg/Zaehne_17.png" />
																			</div>
																		</td>
																		<td>
																			<div class="ya44"
																				@click="showDuiHao4('ya44',44)"><img
																					src="../../assets/ysmimg/Zaehne_18.png" />
																			</div>
																		</td>
																		<td>
																			<div class="ya43"
																				@click="showDuiHao4('ya43',43)"><img
																					src="../../assets/ysmimg/Zaehne_19.png" />
																			</div>
																		</td>
																		<td>
																			<div class="ya42"
																				@click="showDuiHao4('ya42',42)"><img
																					src="../../assets/ysmimg/Zaehne_20.png" />
																			</div>
																		</td>
																		<td>
																			<div class="ya41"
																				@click="showDuiHao4('ya41',41)"><img
																					src="../../assets/ysmimg/Zaehne_21.png" />
																			</div>
																		</td>
																		<td>
																			<div class="ya31"
																				@click="showDuiHao3('ya31',31)"><img
																					src="../../assets/ysmimg/Zaehne_22.png" />
																			</div>
																		</td>
																		<td>
																			<div class="ya32"
																				@click="showDuiHao3('ya32',32)"><img
																					src="../../assets/ysmimg/Zaehne_23.png" />
																			</div>
																		</td>
																		<td>
																			<div class="ya33"
																				@click="showDuiHao3('ya33',33)"><img
																					src="../../assets/ysmimg/Zaehne_24.png" />
																			</div>
																		</td>
																		<td>
																			<div class="ya34"
																				@click="showDuiHao3('ya34',34)"><img
																					src="../../assets/ysmimg/Zaehne_25.png" />
																			</div>
																		</td>
																		<td>
																			<div class="ya35"
																				@click="showDuiHao3('ya35',35)"><img
																					src="../../assets/ysmimg/Zaehne_26.png" />
																			</div>
																		</td>
																		<td>
																			<div class="ya36"
																				@click="showDuiHao3('ya36',36)"><img
																					src="../../assets/ysmimg/Zaehne_27.png" />
																			</div>
																		</td>
																		<td>
																			<div class="ya37"
																				@click="showDuiHao3('ya37',37)"><img
																					src="../../assets/ysmimg/Zaehne_28.png" />
																			</div>
																		</td>
																	</tr>
																	<tr class="select-row xh select-row1">
																		<td>
																			<div class="ya47" id="ya47"
																				@click="showDuiHao4('ya47',47)"></div>
																		</td>
																		<td>
																			<div class="ya46" id="ya46"
																				@click="showDuiHao4('ya46',46)"></div>
																		</td>
																		<td>
																			<div class="ya45" id="ya45"
																				@click="showDuiHao4('ya45',145)"></div>
																		</td>
																		<td>
																			<div class="ya44" id="ya44"
																				@click="showDuiHao4('ya44',44)"></div>
																		</td>
																		<td>
																			<div class="ya43" id="ya43"
																				@click="showDuiHao4('ya43',43)"></div>
																		</td>
																		<td>
																			<div class="ya42" id="ya42"
																				@click="showDuiHao4('ya42',42)"></div>
																		</td>
																		<td>
																			<div class="ya41" id="ya41"
																				@click="showDuiHao4('ya41',41)"></div>
																		</td>
																		<td>
																			<div class="ya31" id="ya31"
																				@click="showDuiHao3('ya31',31)"></div>
																		</td>
																		<td>
																			<div class="ya32" id="ya32"
																				@click="showDuiHao3('ya32',32)"></div>
																		</td>
																		<td>
																			<div class="ya33" id="ya33"
																				@click="showDuiHao3('ya33',33)"></div>
																		</td>
																		<td>
																			<div class="ya34" id="ya34"
																				@click="showDuiHao3('ya34',34)"></div>
																		</td>
																		<td>
																			<div class="ya35" id="ya35"
																				@click="showDuiHao3('ya35',35)"></div>
																		</td>
																		<td>
																			<div class="ya36" id="ya36"
																				@click="showDuiHao3('ya36',36)"></div>
																		</td>
																		<td>
																			<div class="ya37" id="ya37"
																				@click="showDuiHao3('ya37',37)"></div>
																		</td>
																	</tr>
																</tbody>
															</table>
														</td>
													</tr>
												</tbody>
											</table>
										</div>
									</div>
								</el-col>
							</el-row>
							<p><h3 class="cu">治疗类型</h3> <p style="text-indent: 1em;">{{leixing}}</p></p>
						 </div>
					 </div>
					 <!-- 下开始-->
					 <div class="bottom">
						 <div>
						 	<h3>X光片</h3>
						 	<el-table
						 	      :data="xRay"
						 	      style="width: 100%">
						 	      <el-table-column
						 	        prop="type"
						 	        label="文件类型"
						 	       >
						 	      </el-table-column>
						 	      <el-table-column
						 	        prop="date"
						 	        label="成像日期"
						 	       >
						 	      </el-table-column>
						 	      <el-table-column
						 	        label="预览" align="center">
						 			 <template slot-scope="scope">
						 				 <a :href="scope.row.href" target="_blank" v-if="scope.row.yulan==true"><i style="color: #af9f73;font-size: 16px;"  class="el-icon-search"></i></a>
						 			  </template>
						 	      </el-table-column>
						 		  <el-table-column
						 		    label="下载"
						 			align="center"
						 			>
						 			<template slot-scope="scope">
						 				<span @click="xiazai(scope.row.href)"><i style="color: #af9f73;font-size: 18px;" class="el-icon-download"></i></span>   
						 			</template>
						 		  </el-table-column>
						 	    </el-table> 
						 </div>
						 <div>
						 	<h3>口扫片</h3>
						 	<el-table
						 	      :data="ks"
						 	      style="width: 100%">
						 	      <el-table-column
						 	        prop="type"
						 	        label="文件类型"
						 	       >
						 	      </el-table-column>
						 	      <el-table-column
						 	        prop="date"
						 	        label="成像日期"
						 	       >
						 	      </el-table-column>
						 	      <el-table-column
						 	        label="预览" align="center">
						 			 <template slot-scope="scope">
						 				 <a :href="scope.row.href" target="_blank" v-if="scope.row.yulan==true"><i style="color: #af9f73;font-size: 16px;"  class="el-icon-search"></i></a>
						 			  </template>
						 	      </el-table-column>
						 		  <el-table-column
						 		    label="下载"
						 			align="center"
						 			>
						 			<template slot-scope="scope">
						 				<span @click="xiazai(scope.row.href)"><i style="color:#af9f73;font-size: 18px;" class="el-icon-download"></i></span>   
						 			</template>
						 		  </el-table-column>
						 	    </el-table> 
						 </div>
						 <div>
						 	<h3>口内片</h3>
						 	<el-table
						 	      :data="kn"
						 	      style="width: 100%">
						 	      <el-table-column
						 	        prop="type"
						 	        label="文件类型"
						 	       >
						 	      </el-table-column>
						 	      <el-table-column
						 	        prop="date"
						 	        label="成像日期"
						 	       >
						 	      </el-table-column>
						 	      <el-table-column
						 	        label="预览" align="center">
						 			 <template slot-scope="scope">
						 				 <a :href="scope.row.href" target="_blank" v-if="scope.row.yulan==true"><i style="color: #af9f73;font-size: 16px;"  class="el-icon-search"></i></a>
						 			  </template>
						 	      </el-table-column>
						 		  <el-table-column
						 		    label="下载"
						 			align="center"
						 			>
						 			<template slot-scope="scope">
						 				<span @click="xiazai(scope.row.href)"><i style="color: #af9f73;font-size: 18px;" class="el-icon-download"></i></span>   
						 			</template>
						 		  </el-table-column>
						 	    </el-table> 
						 </div>
						 <div>
						 	<h3>面相片</h3>
						 	<el-table
						 	      :data="mx"
						 	      style="width: 100%">
						 	      <el-table-column
						 	        prop="type"
						 	        label="文件类型"
						 	       >
						 	      </el-table-column>
						 	      <el-table-column
						 	        prop="date"
						 	        label="成像日期"
						 	       >
						 	      </el-table-column>
						 	      <el-table-column
						 	        label="预览" align="center">
						 			 <template slot-scope="scope">
						 				 <a :href="scope.row.href" target="_blank" v-if="scope.row.yulan==true"><i style="color: #af9f73;font-size: 16px;"  class="el-icon-search"></i></a>
						 			  </template>
						 	      </el-table-column>
						 		  <el-table-column
						 		    label="下载"
						 			align="center"
						 			>
						 			<template slot-scope="scope">
						 				<span @click="xiazai(scope.row.href)"><i style="color: #af9f73;font-size: 18px;" class="el-icon-download"></i></span>   
						 			</template>
						 		  </el-table-column>
						 	    </el-table> 
						 </div>
					 </div>
					 <!-- 下结束-->
				</div>
			</div>
		</div>

		<div class="f">Copyright&copy;www.techlion.com.cn All Rights Reserved<br />山东泰克莱恩 0513-88762550</div>


	</div>
</template>

<script>
	import {
		Message
	} from 'element-ui';
	export default {
		data: function() {
			return {
				targetList:[],
				shuoming:'',
				checkList:[],
				yalie:'',
				shouhuoUserName:'',
				shouhuoPhone:'',
				shouhuoAddress:'',
				shouhuoCityInfo:'',
				dname:'',
				phone:'',
				company:'',
				stateName:'',
				orderNum:null,
				patientNum: '',
				patientBirthday: '',
				patientGender: '',
				leixing: '',
				mubiao: '',
				xRay:[],
				xRayCompleted: false,
				ks:[],
				ksCompleted: false,
				kn:[],
				knCompleted: false,
				mx:[],
				mxCompleted: false,
				yidongCompleted: false,
				liuyan: '',
				totalCompleted: false,
				ruleForm: {
					oid: ''
				},

				zuoshanglist: [], //储的左上哪些牙要移动
				youshanglist: [], //储的右上哪些牙要移动
				youxialist: [], //存储的右下哪些牙要移动
				zuoxialist: [], //储的左下哪些牙要移动
				

			}
		},
		methods: {
			//下载牙位图片
			xiaZaiYaWei(url){
				window.open("http://120.26.66.95:8089/admin/orderAdmin/downloadQN?url="+url)
			},
			//下载资源图片
			xiazai(url){
				window.open("http://120.26.66.95:8089/admin/orderAdmin/downloadQN?url="+url)
			},
			//下一步
			nextStepOrder() {
				console.log("订单是否通过: " + this.totalCompleted);
				if (this.totalCompleted) {
					this.$store.commit("setYsmStepIndex", 10);
					this.$router.push("/ysmmain/ysmorder/ysmorderten");
				} else {
					this.$alert("订单填写不完整", "提示");
				}
			},
			//返回
			back() {
				this.$store.commit("setYsmStepIndex", 8);
				this.$router.push("/ysmmain/ysmorder/ysmordereight")
			}
		},
		mounted: function() {
			document.getElementsByClassName("fun1")[0].setAttribute("class","fun1 sub-menue1")
			document.getElementsByClassName("fun2")[0].setAttribute("class","fun2 sub-menue2 visible");
			document.getElementsByClassName("fun")[0].setAttribute("class","fun sub-menue ")
			document.getElementsByClassName("fun3")[0].setAttribute("class","fun3 sub-menue3")
			//设置返回路径
			this.$store.commit("setBackLocation","/ysmmain/ysmorderyulan");
			//设置默认地址
			sessionStorage.setItem("location", "/ysmmain/ysmorderyulan")
		},
		created: function() {
			//如果是编辑订单要获取oid的值
			let oid = sessionStorage.getItem("yloid");

			if (oid != "undefined" && oid != '' && oid != null) {
				this.ruleForm.oid = oid;
				this.$axios.get("/client/order/ysmYulan", {
					params: {
						oid: this.ruleForm.oid
					}
				}).then(res => {
					let confirmInfo = res.data.data.orderInfo;
					console.log(confirmInfo);
					//订单编号
					if(confirmInfo.orderNum==null){
						this.orderNum='暂无'
					}else{
						this.orderNum=confirmInfo.orderNum;
					}
					if(res.data.data.targetList!=null){
						this.targetList=res.data.data.targetList;
					}
					//医师信息
					this.dname=confirmInfo.doctor.dname;
					this.phone=confirmInfo.doctor.phone;
					this.company=confirmInfo.doctor.company;
					this.stateName=confirmInfo.doctor.stateName;
					//收货地址信息
					this.shouhuoUserName=confirmInfo.address.userName;
					this.shouhuoPhone=confirmInfo.address.phone;
					this.shouhuoCityInfo=confirmInfo.address.cityInfo;
					this.shouhuoAddress=confirmInfo.address.address;
					//患者信息
					if (confirmInfo.patientNum != null && confirmInfo.patientNum != "") {
						this.patientNum = confirmInfo.patientNum;
					}
					if (confirmInfo.patientBirthday != null && confirmInfo.patientBirthday != "") {
						this.patientBirthday = confirmInfo.patientBirthday;
					}
					if (confirmInfo.patientGender != null && confirmInfo.patientGender != "") {
						this.patientGender = confirmInfo.patientGender;
					}
					//牙列
					this.yalie=confirmInfo.yalie;
					//说明
					this.shuoming=confirmInfo.shuoming;
					if (confirmInfo.leixing != null && confirmInfo.leixing != '') {
						this.leixing = confirmInfo.leixing;
					}
					if (confirmInfo.mubiao != null && confirmInfo.mubiao != '') {
						this.mubiao = confirmInfo.mubiao;
					}
					
						
						this.xRay = confirmInfo.xray;
						this.ks = confirmInfo.kousao;
						this.kn = confirmInfo.kounei;
						this.mx = confirmInfo.mianxiang;
					
					if (confirmInfo.jiancha != null && confirmInfo.jiancha.length > 0) {
						let jc = confirmInfo.jiancha;
						for (let i = 0; i < jc.length; i++) {
							let id = jc[i].yahao;
							let con = jc[i].qingkuang;
							document.getElementById('a' + id).innerHTML = con;
						}
					}
					if (confirmInfo.yidong != null && confirmInfo.yidong.length > 0) {
						let yd = confirmInfo.yidong;
						for (let i = 0; i < yd.length; i++) {
							let id = yd[i];
							let dh = document.getElementById("ya" + id).getAttribute("class");
							document.getElementById("ya" + id).setAttribute("class", dh + " duihao");
						}
					}
					//留言
					//this.liuyan = confirmInfo.plan.liuyan;
					//验证是否通过
					//this.totalCompleted = confirmInfo.totalCompleted;
					//是否有移动的牙齿
					this.yidongCompleted = confirmInfo.yidongCompleted;
					//哪些牙齿变成灰色
					if (confirmInfo.noyidong != null && confirmInfo.noyidong.length > 0) {
						let noyd = confirmInfo.noyidong;
						for (let i = 0; i < noyd.length; i++) {
							let id = noyd[i].substring(1,5);
							let oldClass1 = document.getElementsByClassName(id)[0].getAttribute("class");
							let oldClass2 = document.getElementsByClassName(id)[1].getAttribute("class");
							document.getElementsByClassName(id)[0].setAttribute("class", oldClass1 + " hei");
							document.getElementsByClassName(id)[1].setAttribute("class", oldClass2 + " hei");
							//禁用yayi对应的div的单击事件
							document.getElementsByClassName(id)[0].setAttribute("style",
							"pointer-events:none");
							document.getElementsByClassName(id)[1].setAttribute("style", "pointer-events:none")
							 let n=noyd[i].substring(3,5);
							if(n>=11&&n<=27){
															 document.getElementsByClassName(id)[1].style.backgroundColor="#CCCCCC";
							}else{
															  document.getElementsByClassName(id)[0].style.backgroundColor="#CCCCCC";
							}
						}
					}
					this.checkList=confirmInfo.shiyongxiang;
					//留言
					//console.log(confirmInfo.plan.liuyan);
					//this.liuyan=confirmInfo.plan.liuyan;
					//验证是否通过

				}).catch(err => {
					console.log(err);
				});
			} else {
				this.$alert('请填写患者信息', '提示', {
					confirmButtonText: '确定',
					callback: action => {
						this.$router.push("/ysmmain/ysmorder/ysmorderone");
					}
				});

			}
		}
	}
</script>

<style scoped="scoped">
	.ysm-settings {
		width: 100%;
		height: 100%;
		background-color: transparent;
		overflow-y: auto;
		overflow-x: hidden;
		position: relative;
		margin-bottom: 1.875rem;
		/* z-index: 0; */
	}

	h2 {
		color: #af9f73;
	}

	.conditions p {
		padding-left: 25px;
	}

	.nextStep {
		background-color: #af9f73 !important;
		border-color: #af9f73 !important;
		color: #fff !important;
	}

	.order {
		position: relative;
		background-color: white;
		min-width: 1550px;
		min-height: 31.75rem;
		margin-top: 16.75rem;
		margin-bottom: 15.75rem;
		padding-top: 1.25rem;
		padding-bottom: 10px;
		 display: flex;
		 flex-direction: column;
		 align-items: center;
		justify-content: center; 
	}



	/* 条件 */
	.conditions {
		box-sizing: border-box;
		/* background-color: #f0f0ee; */
		border-radius: 15px;
		padding: 10px 20px 20px 20px;
		width: 500px;
		margin: 0px auto;
		margin-left: 10%;
		padding-left: 100px;
		/* border: 1px solid red; */
		position: relative;
		left: -100px;
	}

	.right {
		width: 500px;
		/* border: 1px solid blue; */
		min-height: 31.75rem;
		position: relative;
		left: -100px;
	}

	.ress {
		/* border: 1px solid red; */
		/* background-color: lightgray; */
		/* border-radius: 15px; */
		padding-left: 10px;
		height: 120px;

	}

	.ress p {}

	.primary {
		margin: 0px 0px 1px;
		padding: 6px 12px 6px 12px;
		background-color: #af9f73 !important;
		border-color: #af9f73 !important;
		color: #fff !important;
		border-radius: 0px;
		width: 5.125rem;
		height: 2.3rem;
	}

	.f {
		position: relative;
		background-color: #444;
		padding: 10px 0 10px 0;
		color: #fff;
		bottom: 0rem;
	}

	.btj {
		min-width: 7.5rem;
		background-color: transparent;
		border-color: #af9f73 !important;
		color: #af9f73 !important;
	}
	.orderno{
		font-size: 20px;
		text-align: left;
		border-bottom: 2px solid #af9f73;
		margin-bottom: 30px;
		padding-bottom: 10px;
	}
	/* 单击28颗牙齿的时候出现对号 */
	.duihao {
		background: url(../../assets/ysmimg/duihao.png);
		background-repeat: no-repeat;
		background-position: center;
		background-size: 70% 70%;
	}
	h3 {
		/* font-family: 'themixitalic';
		font-weight: 400;
		line-height: 1.1;
		color: #af9f73;
		margin-bottom: 20px;
		font-size: 24px; */
	}
	.hzinfo span {
		font-size: 0.9375rem;
		display: inline-block;
		margin-left: 1.25rem;
	}
	/* 第二部分 */
	.con {
		position: relative;
		/* left: 9.55rem; */
		text-align: left;
	}
	
	/* 32颗牙 */
	.medical-report-ctrl {
		width: 100%;
	}
	
	.jianchaitem {
		color: #999999;
		font-size: 12px;
	}
	
	table {
		border: 0;
		background-color: transparent;
		width: 100%;
		border-spacing: 0;
		border-collapse: collapse;
	}
	
	table.container-table {
		border: solid 4px #ccc;
	}
	
	/* 左L右R的背景颜色 */
	table.container-table td.label-column {
		text-align: left;
		padding: 5px;
		background-color: #ccc;
	}
	
	/* 右R */
	table.container-table td.label-column:first-child {
		padding-right: 10px !important;
	}
	
	/* 左L */
	table.container-table td.label-column:last-child {
		padding-left: 10px !important;
	}
	
	table td {
		text-align: center !important;
		vertical-align: middle !important;
		border: 0 !important;
		padding: 0 !important;
	}
	
	.medical-report-ctrl table.report-table tr.select-row td div {
		height: 24px;
		line-height: 1.5rem;
		border: solid 2px #ccc;
		font-size: 13px;
		text-align: center;
		padding-top: 3px;
		cursor: pointer;
	}
	
	table.report-table td div {
		border-top: 0 !important;
		border-bottom: 0 !important;
		min-width: 31px;
		white-space: nowrap;
	}
	
	.medical-report-ctrl table.report-table td:nth-child(1) div {
		border-left: 0 !important;
		min-width: 29px;
	}
	
	/* table.report-table中的第二行的tr下边框褐色 */
	.medical-report-ctrl table.report-table tr:nth-child(2) td {
		border-bottom: solid 2px #333 !important;
	}
	
	/* 序号行的td的背景颜色 */
	.medical-report-ctrl table.report-table tr.label-row td {
		height: 15px;
		background-color: #ccc;
	}
	
	/* 白色隔条 */
	td.label-column-space div {
		background-color: #fff;
		width: 0.1875rem;
		border-left: 0;
		border-right: 0;
	}
	
	/* 最后一个td的div */
	.medical-report-ctrl table.report-table td:nth-last-child(1) div {
		border-right: 0 !important;
		min-width: 29px;
	}
	
	/* 竖线 */
	.medical-report-ctrl table.report-table tr.label-row td:nth-child(9) {
		border-left: solid 2px #333 !important;
	}
	
	/* =======================28颗牙齿============================== */
	tr {
		display: table-row;
	}
	
	.teeth-selection-ctrl {
		background-color: white;
	
	}
	
	.teeth-selection-ctrl table.container-table {
		border: 0;
	}
	
	.teeth-selection-ctrl table.selection-table {
		border: solid 1px #ccc;
		border-left-width: 2px !important;
		border-right-width: 2px !important;
	}
	
	.teeth-selection-ctrl table td {
		text-align: center !important;
		vertical-align: middle !important;
		background-color: #ccc;
		padding: 0 !important;
	
	}
	
	.teeth-selection-ctrl table.container-table th {
		font-size: 12px;
		color: #999;
	}
	
	th {
		font-weight: bold;
	}
	
	.teeth-selection-ctrl table.selection-table tr.select-row td:first-child div {
		border-left-color: #ccc !important;
	}
	
	.teeth-selection-ctrl table.selection-table tr.select-row td div {
		height: 28px;
		font-size: 22px;
		line-height: 25px;
		font-weight: bold;
		text-align: center;
		vertical-align: middle;
		border-right-color: #fff !important;
		border-left-color: #fff !important;
		color: #fff;
		background-color: #CCCCCC;
	}
	
	.teeth-selection-ctrl table.selection-table td div {
		min-width: 41px;
		cursor: pointer;
		border: solid 1px #ccc;
	
	}
	
	.teeth-selection-ctrl table.selection-table td * {
		box-sizing: content-box;
	}
	
	.teeth-selection-ctrl table.selection-table tr.teeth-row td div {
		height: 37px;
		font-size: 13px;
		text-align: center;
		background-color: #fff;
	}
	
	.teeth-selection-ctrl table.selection-table tr:nth-child(2) {
		border-bottom: solid 2px #CCCCCC !important;
	}
	
	.teeth-selection-ctrl table.selection-table td:nth-child(7) div {
		border-right-width: 3px !important;
	}
	
	.teeth-selection-ctrl table.selection-table td:nth-last-child(1) div {
		border-right: 0 !important;
		min-width: 38px;
	}
	
	.infoimg {
		width: 5.625rem;
		height: 6.875rem;
	}
	
	img {
		border: 0;
		width: 2.3125rem;
		height: 2.3125rem;
	}
	#hei {
		filter: grayscale(100%);
		-webkit-filter: grayscale(100%);
		-moz-filter: grayscale(100%);
		-ms-filter: grayscale(100%);
		-o-filter: grayscale(100%);
		filter: progid:DXImageTransform.Microsoft.BasicImage(grayscale=1);
		-webkit-filter: grayscale(1);
		pointer-events: none;
	}
	.hei {
		filter: grayscale(100%);
		-webkit-filter: grayscale(100%);
		-moz-filter: grayscale(100%);
		-ms-filter: grayscale(100%);
		-o-filter: grayscale(100%);
		filter: progid:DXImageTransform.Microsoft.BasicImage(grayscale=1);
		-webkit-filter: grayscale(1);
		pointer-events: none;
	}
	.cu{
		font-weight: 550;
	}
</style>
